<template>
  <div>
    <v-row dense class="my-2">
      <v-col cols="12">
        <span class="title font-weight-bold">Shipping Address</span>
      </v-col>
    </v-row>
    <v-row dense class="my-2">
      <v-col cols="12">
        <span class="subtitle-1 font-weight-bold">Recipient Info</span>
      </v-col>
    </v-row>
    <v-form lazy-validation ref="formShip">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            name="name"
            type="text"
            label="Name *"
            dense
            autocomplete="off"
            v-model="shipping_address.name"
            class="subtitle-1"
            :readonly="isReadOnly"
            :rules="rules.requireCheck"
            @change="
              updateUIState({
                key: 'name',
                value: shipping_address.name,
              })
            "
            data-cy="rfq-shipping-name"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            name="company"
            type="text"
            label="Company *"
            autocomplete="off"
            dense
            class="subtitle-1"
            v-model="shipping_address.company_name"
            :readonly="isReadOnly"
            :rules="rules.requireCheck"
            @change="
              updateUIState({
                key: 'company_name',
                value: shipping_address.company_name,
              })
            "
            data-cy="rfq-shipping-company-name"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="email"
                type="text"
                label="Email *"
                autocomplete="off"
                dense
                class="subtitle-1"
                v-model="shipping_address.email"
                :readonly="isReadOnly"
                :rules="rules.requireCheck.concat(rules.emailCheck)"
                @change="
                  updateUIState({
                    key: 'email',
                    value: shipping_address.email,
                  })
                "
                data-cy="rfq-shipping-email"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="phone"
                type="text"
                label="Phone *"
                autocomplete="off"
                dense
                class="subtitle-1"
                v-model="shipping_address.phone"
                :readonly="isReadOnly"
                :rules="rules.requireCheck.concat(rules.phoneNumberCheck)"
                @change="
                  updateUIState({
                    key: 'phone',
                    value: shipping_address.phone,
                  })
                "
                data-cy="rfq-shipping-phone"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            label="Country *"
            :items="country_list"
            item-text="name"
            item-value="code"
            v-model="shipping_address.country"
            dense
            class="subtitle-1"
            :readonly="isReadOnly"
            :rules="rules.requireCheck"
            @change="
              updateUIState({
                key: 'country',
                value: shipping_address.country,
              })
            "
            data-cy="rfq-shipping-country"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            name="street_name1"
            type="text"
            label="Street *"
            autocomplete="off"
            v-model="shipping_address.street1"
            dense
            class="subtitle-1"
            :readonly="isReadOnly"
            :rules="rules.requireCheck"
            @change="
              updateUIState({
                key: 'street1',
                value: shipping_address.street1,
              })
            "
            data-cy="rfq-shipping-street1"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="street_name2"
                type="text"
                label="Street (line 2)"
                autocomplete="off"
                dense
                class="subtitle-1"
                v-model="shipping_address.street2"
                :readonly="isReadOnly"
                @change="
                  updateUIState({
                    key: 'street2',
                    value: shipping_address.street2,
                  })
                "
                data-cy="rfq-shipping-street2"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="city"
                type="text"
                label="City *"
                autocomplete="off"
                v-model="shipping_address.city"
                dense
                class="subtitle-1"
                :readonly="isReadOnly"
                :rules="rules.requireCheck"
                @change="
                  updateUIState({
                    key: 'city',
                    value: shipping_address.city,
                  })
                "
                data-cy="rfq-shipping-city"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="state"
                type="text"
                label="State *"
                autocomplete="off"
                v-model="shipping_address.state"
                dense
                class="subtitle-1"
                :readonly="isReadOnly"
                :rules="rules.requireCheck"
                @change="
                  updateUIState({
                    key: 'state',
                    value: shipping_address.state,
                  })
                "
                data-cy="rfq-shipping-state"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="zip"
                type="text"
                label="Zip *"
                autocomplete="off"
                v-model="shipping_address.zip"
                dense
                class="subtitle-1"
                :readonly="isReadOnly"
                :rules="rules.requireCheck"
                @change="
                  updateUIState({
                    key: 'zip',
                    value: shipping_address.zip,
                  })
                "
                data-cy="rfq-shipping-zip"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import country from "country-list";
export default {
  name: "ShippingAddressForm",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
    company: {
      type: Object,
      default: null,
    },
    quote: {
      type: Object,
      default: null,
    },
    shippingAddress: {
      type: Object,
      default: null,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shipping_address: {
        name:
          (this.editMode || this.shippingAddress) &&
          !this.quote.shipping_same_as_billing
            ? this.shippingAddress.name
            : null,
        street1:
          (this.editMode || this.shippingAddress) &&
          !this.quote.shipping_same_as_billing
            ? this.shippingAddress.street1
            : null,
        street2:
          (this.editMode || this.shippingAddress) &&
          !this.quote.shipping_same_as_billing
            ? this.shippingAddress.street2
            : null,
        city:
          (this.editMode || this.shippingAddress) &&
          !this.quote.shipping_same_as_billing
            ? this.shippingAddress.city
            : null,
        state:
          (this.editMode || this.shippingAddress) &&
          !this.quote.shipping_same_as_billing
            ? this.shippingAddress.state
            : null,
        zip:
          (this.editMode || this.shippingAddress) &&
          !this.quote.shipping_same_as_billing
            ? this.shippingAddress.zip
            : null,
        country:
          (this.editMode || this.shippingAddress) &&
          !this.quote.shipping_same_as_billing
            ? this.shippingAddress.country
            : null,
        company_name:
          (this.editMode || this.shippingAddress) &&
          !this.quote.shipping_same_as_billing
            ? this.shippingAddress.company_name
            : null,
        phone:
          (this.editMode || this.shippingAddress) &&
          !this.quote.shipping_same_as_billing
            ? this.shippingAddress.phone
            : null,
        email:
          (this.editMode || this.shippingAddress) &&
          !this.quote.shipping_same_as_billing
            ? this.shippingAddress.email
            : null,
      },
      rules: {
        requireCheck: [(v) => !!v || "Input is required"],
        emailCheck: [
          (v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || "Email is invalid";
          },
        ],
        phoneNumberCheck: [
          (v) =>
            Number.isInteger(Number(v)) ||
            "The value must be an integer number",
        ],
      },
      country_list: country.getData(),
    };
  },
  methods: {
    isShippingAddressValid() {
      return this.$refs.formShip.validate();
    },
    updateUIState(payload) {
      this.$store.dispatch("ui/updateShippingAddress", payload);
    },
    refreshAddressFields() {
      this.shipping_address.name = this.shippingAddress
        ? this.shippingAddress.name
        : null;
      this.shipping_address.street1 = this.shippingAddress
        ? this.shippingAddress.street1
        : null;
      this.shipping_address.street2 = this.shippingAddress
        ? this.shippingAddress.street2
        : null;
      this.shipping_address.city = this.shippingAddress
        ? this.shippingAddress.city
        : null;
      this.shipping_address.state = this.shippingAddress
        ? this.shippingAddress.state
        : null;
      this.shipping_address.zip = this.shippingAddress
        ? this.shippingAddress.zip
        : null;
      this.shipping_address.country = this.shippingAddress
        ? this.shippingAddress.country
        : null;
      this.shipping_address.company_name = this.shippingAddress
        ? this.shippingAddress.company_name
        : null;
      this.shipping_address.phone = this.shippingAddress
        ? this.shippingAddress.phone
        : null;
      this.shipping_address.email = this.shippingAddress
        ? this.shippingAddress.email
        : null;
      this.updateAllUIState();
    },
    updateAllUIState() {
      this.updateUIState({
        key: "name",
        value: this.shippingAddress.name,
      });
      this.updateUIState({
        key: "email",
        value: this.shippingAddress.email,
      });
      this.updateUIState({
        key: "company_name",
        value: this.shippingAddress.company_name,
      });
      this.updateUIState({
        key: "phone",
        value: this.shippingAddress.phone,
      });
      this.updateUIState({
        key: "street1",
        value: this.shippingAddress.street1,
      });
      this.updateUIState({
        key: "street2",
        value: this.shippingAddress.street2,
      });
      this.updateUIState({
        key: "city",
        value: this.shippingAddress.city,
      });
      this.updateUIState({
        key: "state",
        value: this.shippingAddress.state,
      });
      this.updateUIState({
        key: "zip",
        value: this.shippingAddress.zip,
      });
      this.updateUIState({
        key: "country",
        value: this.shippingAddress.country,
      });
    },
  },
  mounted() {
    if (
      this.shippingAddress &&
      Object.keys(this.shippingAddress).length > 0 &&
      !this.quote.shipping_same_as_billing
    ) {
      this.updateAllUIState();
    }
  },
};
</script>
