var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"title font-weight-bold"},[_vm._v("Shipping Address")])])],1),_c('v-row',{staticClass:"my-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v("Recipient Info")])])],1),_c('v-form',{ref:"formShip",attrs:{"lazy-validation":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"name","type":"text","label":"Name *","dense":"","autocomplete":"off","readonly":_vm.isReadOnly,"rules":_vm.rules.requireCheck,"data-cy":"rfq-shipping-name"},on:{"change":function($event){return _vm.updateUIState({
              key: 'name',
              value: _vm.shipping_address.name,
            })}},model:{value:(_vm.shipping_address.name),callback:function ($$v) {_vm.$set(_vm.shipping_address, "name", $$v)},expression:"shipping_address.name"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"company","type":"text","label":"Company *","autocomplete":"off","dense":"","readonly":_vm.isReadOnly,"rules":_vm.rules.requireCheck,"data-cy":"rfq-shipping-company-name"},on:{"change":function($event){return _vm.updateUIState({
              key: 'company_name',
              value: _vm.shipping_address.company_name,
            })}},model:{value:(_vm.shipping_address.company_name),callback:function ($$v) {_vm.$set(_vm.shipping_address, "company_name", $$v)},expression:"shipping_address.company_name"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"email","type":"text","label":"Email *","autocomplete":"off","dense":"","readonly":_vm.isReadOnly,"rules":_vm.rules.requireCheck.concat(_vm.rules.emailCheck),"data-cy":"rfq-shipping-email"},on:{"change":function($event){return _vm.updateUIState({
                  key: 'email',
                  value: _vm.shipping_address.email,
                })}},model:{value:(_vm.shipping_address.email),callback:function ($$v) {_vm.$set(_vm.shipping_address, "email", $$v)},expression:"shipping_address.email"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"phone","type":"text","label":"Phone *","autocomplete":"off","dense":"","readonly":_vm.isReadOnly,"rules":_vm.rules.requireCheck.concat(_vm.rules.phoneNumberCheck),"data-cy":"rfq-shipping-phone"},on:{"change":function($event){return _vm.updateUIState({
                  key: 'phone',
                  value: _vm.shipping_address.phone,
                })}},model:{value:(_vm.shipping_address.phone),callback:function ($$v) {_vm.$set(_vm.shipping_address, "phone", $$v)},expression:"shipping_address.phone"}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"subtitle-1",attrs:{"label":"Country *","items":_vm.country_list,"item-text":"name","item-value":"code","dense":"","readonly":_vm.isReadOnly,"rules":_vm.rules.requireCheck,"data-cy":"rfq-shipping-country"},on:{"change":function($event){return _vm.updateUIState({
              key: 'country',
              value: _vm.shipping_address.country,
            })}},model:{value:(_vm.shipping_address.country),callback:function ($$v) {_vm.$set(_vm.shipping_address, "country", $$v)},expression:"shipping_address.country"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"street_name1","type":"text","label":"Street *","autocomplete":"off","dense":"","readonly":_vm.isReadOnly,"rules":_vm.rules.requireCheck,"data-cy":"rfq-shipping-street1"},on:{"change":function($event){return _vm.updateUIState({
              key: 'street1',
              value: _vm.shipping_address.street1,
            })}},model:{value:(_vm.shipping_address.street1),callback:function ($$v) {_vm.$set(_vm.shipping_address, "street1", $$v)},expression:"shipping_address.street1"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"street_name2","type":"text","label":"Street (line 2)","autocomplete":"off","dense":"","readonly":_vm.isReadOnly,"data-cy":"rfq-shipping-street2"},on:{"change":function($event){return _vm.updateUIState({
                  key: 'street2',
                  value: _vm.shipping_address.street2,
                })}},model:{value:(_vm.shipping_address.street2),callback:function ($$v) {_vm.$set(_vm.shipping_address, "street2", $$v)},expression:"shipping_address.street2"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"city","type":"text","label":"City *","autocomplete":"off","dense":"","readonly":_vm.isReadOnly,"rules":_vm.rules.requireCheck,"data-cy":"rfq-shipping-city"},on:{"change":function($event){return _vm.updateUIState({
                  key: 'city',
                  value: _vm.shipping_address.city,
                })}},model:{value:(_vm.shipping_address.city),callback:function ($$v) {_vm.$set(_vm.shipping_address, "city", $$v)},expression:"shipping_address.city"}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"state","type":"text","label":"State *","autocomplete":"off","dense":"","readonly":_vm.isReadOnly,"rules":_vm.rules.requireCheck,"data-cy":"rfq-shipping-state"},on:{"change":function($event){return _vm.updateUIState({
                  key: 'state',
                  value: _vm.shipping_address.state,
                })}},model:{value:(_vm.shipping_address.state),callback:function ($$v) {_vm.$set(_vm.shipping_address, "state", $$v)},expression:"shipping_address.state"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"subtitle-1",attrs:{"name":"zip","type":"text","label":"Zip *","autocomplete":"off","dense":"","readonly":_vm.isReadOnly,"rules":_vm.rules.requireCheck,"data-cy":"rfq-shipping-zip"},on:{"change":function($event){return _vm.updateUIState({
                  key: 'zip',
                  value: _vm.shipping_address.zip,
                })}},model:{value:(_vm.shipping_address.zip),callback:function ($$v) {_vm.$set(_vm.shipping_address, "zip", $$v)},expression:"shipping_address.zip"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }